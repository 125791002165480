<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">#{{ data.id }}</h1>
    </div>
    <div class="card-content">
      <div class="columns view-columns">
        <div class="column ">
          <label>Artikel-ID</label>
          #{{ $utilities.padLeftWithNull(data.id) }}
        </div>
        <div class="column ">
          <label>Produzent, Name</label>
          {{ data.wine.producer.producer_name_de }}, {{ data.wine.name_de }}
        </div>
        <div class="column ">
          <label>Jahrgang</label>
          {{ data.wine.jahr == 0 ? "n.V." : data.wine.jahr }}
        </div>
      </div>

      <div class="columns view-columns">
        <div class="column ">
          <label>Flaschen</label>
          {{ data.flaschen_pro_einheit }}
        </div>
        <div class="column ">
          <label>Flaschengröße</label>
          {{ data.menge }} L
        </div>

        <div class="column ">
          <label>Promotion</label>
          {{ data.specialoffer ==1 ? 'aktiv' : '-'}}
        </div>
      </div>

      <div class="columns view-columns">
        <div class="column ">
          <label>Preis netto</label>
          {{ nf.format(data.preis_net) }} €/Fl.
        </div>
        <div class="column ">
          <label>Preis differenzbesteuert inkl. USt.</label>
          {{ nf.format(data.preis_from_private)}} €/Fl.
        </div>

        <div class="column ">
          <label>Lwin18-Code</label>
          {{ data.lwin18 }}
        </div>
      </div>

      <div class="columns view-columns">
        <div class="column ">
          <label>Bestand normal </label>
         {{ data.bottles }} Flaschen <br />+ {{ data.bottles_reserved }} reservierte Flaschen<br />
         
        </div> 
        <div class="column ">
          <label>Bestand differenzbesteuert </label>
        {{ data.bottles_from_private }} Flaschen <br />+ {{ data.bottles_from_private_reserved }} reservierte Flaschen<br />
        </div>        
      </div>

      <b-button
        class="button"
        type="is-info"
        @click="edit(data.id)"
        icon-left="square-edit-outline"
        >Bearbeiten</b-button
      >

      &nbsp;

      <b-button
        class="button"
        type="is-danger"
        icon-left="delete_forever"
        @click="
          delete1(
            data.id,
            data.wine.name_de +
              ' ' +
              data.wine.jahr +
              ': ' +
              data.flaschen_pro_einheit +
              ' x ' +
              data.menge
          )
        "
        >Löschen</b-button
      >
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "articlesView",
  props: ["data"],
  data () {
    return {
      nf: Intl.NumberFormat(),
    }
  },

  methods: {
    delete1: function(id, name) {
      this.$parent.close()
      this.$parent.$parent.delete1(id, name)
    },
    edit: function(id) {
      this.$parent.close()
      this.$parent.$parent.edit(id)
    }
  }
}
</script>

<template>
  <div class="card">
    <form method="post" @submit.prevent="save">
      <div class="card-header">
        <h1 class="card-header-title">{{ typeOfAction }}</h1>
      </div>
      <div class="card-content">
        <div class="columns view-columns">
          <div class="column">
            <b-field label="Artikel-ID"> #{{ $utilities.padLeftWithNull(data.id) }} </b-field>
          </div>
        </div>
        <div class="columns view-columns">
          <div class="column">
            <b-field label="Produzent, Name">
              <b-autocomplete
                v-if="!wineSelected"
                :data="wineList"
                placeholder="Wein auswählen"
                field="title"
                :loading="isFetching"
                @typing="getAsyncData"
                @select="(option) => setWine(option)"
              >
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      {{ props.option.producer_name_de }}, {{ props.option.wine_name_de }},
                      {{ props.option.jahr == 0 ? "n.V." : props.option.jahr }}, W{{ props.option.wine_id }}
                    </div>
                  </div>
                </template>
              </b-autocomplete>
              <div v-if="wineSelected">{{ wine }} {{ jahr }}</div>
            </b-field>
          </div>
        </div>

        <div class="columns view-columns">
          <div class="column">
            <b-field
              label="Flaschen"
              :type="{ 'is-danger': $v.data.flaschen_pro_einheit.$error }"
              :message="{
                'Bitte geben Sie die Anzahl der Flaschen an.': submitted && $v.data.flaschen_pro_einheit.$error,
              }"
            >
              <b-select v-model.number="$v.data.flaschen_pro_einheit.$model">
                <option v-for="option in selectBottles" :value="option.id" :key="option.id">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="column">
            <b-field
              label="Flaschengröße"
              :type="{ 'is-danger': $v.data.menge.$error }"
              :message="{
                'Bitte geben Sie eine Flaschengröße ein.': submitted && $v.data.menge.$error,
              }"
            >
              <b-select v-model.number="$v.data.menge.$model">
                <option v-for="option in bottleSizes" :value="option.id" :key="option.id">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Promotion"
              :type="{ 'is-danger': $v.data.lwin18.$error }"
              :message="{
                'Bitte geben Sie einen gültigen Lwin18-Code an (18 Ziffern).': submitted && $v.data.lwin18.$error,
              }"
            >
              <b-switch v-model.trim="$v.data.specialoffer.$model" true-value="1" false-value="0" size="is-small"
                >Auf Startseite und in Trefferlisten hervorheben
              </b-switch>
            </b-field>
          </div>
        </div>

        <div class="columns view-columns">
          <div class="column">
            <b-field
              :addons="true"
              label="Preis netto"
              :type="{ 'is-danger': $v.data.preis_net.$error }"
              :message="{
                'Bitte geben Sie einen gültigen Preis an.': submitted && $v.data.preis_net.$error,
              }"
            >
              <b-input v-model="$v.data.preis_net.$model" custom-class="has-text-right" @input="formatPrice('preis_net')"> </b-input>
              <p class="control">
                <span class="button is-static">€/Fl.</span>
              </p>
            </b-field>
          </div>

          <div class="column">
            <b-field
              :addons="true"
              label="Preis differenzbesteuert inkl. USt."
              :type="{ 'is-danger': $v.data.preis_from_private.$error }"
              :message="{
                'Bitte geben Sie einen gültigen Preis an.': $v.data.preis_from_private.$error,
              }"
            >
              <b-input v-model="$v.data.preis_from_private.$model" custom-class="has-text-right"  @input="formatPrice('preis_from_private')"> </b-input>
              <p class="control">
                <span class="button is-static">€/Fl.</span>
              </p>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Lwin18-Code"
              :type="{ 'is-danger': $v.data.lwin18.$error }"
              :message="{
                'Bitte geben Sie einen gültigen Lwin18-Code an (18 Ziffern).': submitted && $v.data.lwin18.$error,
              }"
            >
              <b-input v-model.trim="$v.data.lwin18.$model" :class="{ is_danger: $v.data.lwin18.$error }"> </b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="column">
          <b-button class="button" type="button" @click="$parent.close()">Schließen</b-button>
          &nbsp;

          <b-button class="button" type="is-info" native-type="submit" icon-left="cloud_upload">Speichern</b-button>
        </div>
      </div>
    </form>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { required, minLength, maxLength, numeric, decimal } from "vuelidate/lib/validators";
export default {
  name: "articlesForm",
  props: ["pdata"],

  data() {
    return {
      data: {},
      error: null,
      nf: Intl.NumberFormat(),
      isLoading: false,
      wine: "",
      wineSelected: false,
      jahr: 0,
      submitted: false,
      wineList: [],
      isFetching: false,
      selectBottles: this.selectBottlesRange(),
      bottleSizes: [
        { id: "0.250", name: "0,25 L" },
        { id: "0.375", name: "0,375 L" },
        { id: "0.500", name: "0,50 L" },
        { id: "0.700", name: "0,70 L" },
        { id: "0.750", name: "0,75 L" },
        { id: "1.000", name: "1,0 L" },
        { id: "1.500", name: "1,5 L" },
        { id: "2.250", name: "2,25 L" },
        { id: "3.000", name: "3,0 L" },
        { id: "3.150", name: "3,15 L" },
        { id: "3.750", name: "3,75 L" },
        { id: "4.500", name: "4,5L" },
        { id: "5.000", name: "5,0 L" },
        { id: "6.000", name: "6,0 L" },
        { id: "7.750", name: "7,75 L" },
        { id: "9.000", name: "9,0 L" },
        { id: "12.000", name: "12,0 L" },
        { id: "15.000", name: "15,0 L" },
        { id: "18.000", name: "18,0 L" },
      ],
    };
  },

  computed: {
    
    typeOfAction: function () {
      if (typeof this.data.id == "undefined" || this.data.id == null) {
        return "Neuen Artikel anlegen";
      }
      return "Artikel bearbeiten: #" + this.data.id;
    },
  },

  validations: {
    data: {
      menge: {
        required,
        minLength: minLength(1),
      },
      flaschen_pro_einheit: {
        required,
        numeric,
      },
      wine_id: {
        numeric,
        required,
      },
      lwin18: {
        minLength: minLength(18),
        maxLength: maxLength(18),
      },
      specialoffer: {
        numeric,
        required,
      },
      preis_net: {decimal, required},
      preis_from_private: {decimal, required},
    },
  },

  created: function () {
    if (typeof this.data.id == "undefined" || this.data.id == null) {
      this.wineSelected = false;
    } else {
      this.wineSelected = true;
      this.wine = this.data.wine.producer.producer_name_de + ", " + this.data.wine.name_de;
      this.jahr = this.data.wine.jahr;
    }
    this.data = this.pdata;
  },

  methods: {
    save: async function () {
      this.submitted = true;
      this.$v.$touch();
      var t = this;

      if (t.$v.$invalid === true) {
        console.log("Fehler in Form");
        console.log(t.data.lwin18.length);
      } else {
        t.$parent.$parent.save({
          lwin18: t.data.lwin18,
          wine_id: t.data.wine_id,
          flaschen_pro_einheit: t.data.flaschen_pro_einheit,
          menge: t.data.menge,
          id: t.data.id,
          specialoffer: t.data.specialoffer,
          preis_net: t.data.preis_net,
          preis_from_private: t.data.preis_from_private
        });
      }
    },

    selectBottlesRange: function () {
      var sel = [];
      for (var i = 1; i <= 24; i++) {
        sel.push({ id: i, name: i + " Fl." });
      }
      return sel;
    },
    getAsyncData: debounce(function (searchphrase) {
      if (searchphrase.length < 3) {
        return;
      }
      var t = this;
      this.isFetching = true;
      this.$http
        .post("/wines/autocomplete-wine", { search: searchphrase, perpage: 10 })
        .then(function (resp) {
          t.wineList = resp.data.data;
          t.isFetching = false;
        })
        .catch((error) => {
          t.data = [];
          throw error;
        })
        .finally(() => {
          t.isFetching = false;
        });
    }, 200),
    setWine(option) {
      //console.log(option);
      this.wine = option.producer_name_de + ", " + option.wine_name_de;
      this.jahr = option.jahr;
      this.wineSelected = true;
      this.data.wine_id = option.wine_id;
    },

    formatPrice (field)
    {
      console.log('yeah');
      var s = this.data[field].replace(',', '.');
      this.data[field] = parseFloat(s);
    }
  },
};
</script>
<style></style>

<template>
 
  <b-table
    :data="data.data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    :range-before="5"
    :range-after="5"
    paginated
    backend-pagination
    pagination-position="both"
    :total="data.count"
    :per-page="data.perpage"
    @page-change="onPageChange"
    :current-page.sync="data.page"
    aria-next-label="Weiter"
    aria-previous-label="Zurück"
    aria-page-label=" Seite "
    aria-current-label="Aktuelle Seite"
    backend-sorting
    :default-sort="[data.sortField, data.sortOrder]"
    @sort="onSort"
    icon-pack="icm"
    :mobile-cards="false"
  >
    <template slot="top-left">
      <div>
        <b-field grouped>
          <b-field class="control has-icons-right">
            <input
              class="input"
              placeholder="Suche"
              v-model="searchphrase"
              v-on:keyup.enter="list()"
            />
            <span class="icon is-right" @click="list()" id="searchbutton">
              <i class="icm icm-search"></i>
            </span>
          </b-field>
          <b-field class="control">
            <a
              :to="add"
              @click="add()"
              type="is-link"
              class="navbar-link is-arrowless"
              ><b-icon icon="plus1"></b-icon
            ></a>
          </b-field>
        </b-field>
        <span class="smallgrey3"><b-icon icon="filter"></b-icon>
        <b-checkbox size="is-small" v-model="data.filter.promo" @input="list()" true-value="1" false-value="0">Promo</b-checkbox>
        <b-checkbox size="is-small" v-model="data.filter.btl" @input="list()"  true-value="1" false-value="0">Bestand</b-checkbox>
        <b-checkbox size="is-small" v-model="data.filter.btlfp" @input="list()" true-value="1" false-value="0">Bestand differenzbesteuert (ds)</b-checkbox>
        <b-checkbox size="is-small" v-model="data.filter.nlwin" @input="list()" true-value="1" false-value="0">ohne Lwin</b-checkbox>
        <b-checkbox size="is-small" v-model="data.filter.nprice" @input="list()" true-value="1" false-value="0">ohne Preis</b-checkbox>
        </span>
      </div>
    </template>
      <b-table-column v-slot="props"  field="id" label="Id" numeric sortable>
        {{ $utilities.padLeftWithNull(props.row.id) }}
      </b-table-column>

       <b-table-column v-slot="props"  field="specialoffer" label="PR." sortable>
         <b-icon icon="finance" :type="(props.row.specialoffer == 1 ) ? 'is-success' : 'is-grey-light'" title="Promo"></b-icon>
      </b-table-column>

      <b-table-column v-slot="props"  field="producer_name_de" label="Wein" sortable>
        {{ props.row.producer_name_de }}, {{ props.row.wine_name_de }}
      </b-table-column>

      <b-table-column v-slot="props"  field="jahr" label="Jahr" sortable>
        {{ (props.row.jahr == 0) ? 'n.V.' :props.row.jahr }}
      </b-table-column>

      <b-table-column v-slot="props"  field="flaschen_pro_einheit" label="Einheit" sortable numeric>
        {{ props.row.flaschen_pro_einheit }} x {{ props.row.menge }}
      </b-table-column>

      <b-table-column v-slot="props"  field="lwin18" label="Lwin" sortable>
       <b-icon :icon="(props.row.lwin18 != null ) ? 'check_box' : 'cancel'" :type="(props.row.lwin18 != null ) ? 'is-success' : 'is-danger'"></b-icon> 
      </b-table-column>

      <b-table-column v-slot="props"  field="bottles" label="Flaschen" numeric  title="Preis netto exkl. USt.">
      <span class="smallgrey3">[+{{ props.row.bottles_reserved }}]</span>&nbsp;{{ props.row.bottles }}
      </b-table-column>

      <b-table-column v-slot="props"  field="preis_net" label="Flaschen ds" numeric  title="Preis netto exkl. USt.">
       <span class="smallgrey3">[+{{ props.row.bottles_from_private_reserved }}]</span>&nbsp;{{ props.row.bottles_from_private }}
      </b-table-column>

      <b-table-column v-slot="props"  field="preis_net" label="Preis net" numeric  title="Preis netto exkl. USt.">
       {{ nf.format(props.row.preis_net) }}€
      </b-table-column>

      <b-table-column v-slot="props"  field="preis_from_private" label="Preis ds" numeric title="Preis differenzbesteuert inkl. USt.">
       {{ nf.format(props.row.preis_from_private) }}€
      </b-table-column>

    

      <b-table-column v-slot="props" 
        label="A"
        class="iconlink"
        field="link1"
        header-class="iconlink"
      >
        <a title="Ansehen" @click.prevent="view(props.row.id)">
          <b-icon icon="find_in_page"></b-icon>
        </a>
      </b-table-column>

     <b-table-column v-slot="props" 
        label="B"
        class="editlink"
        field="link2"
        header-class="iconlink"
      >
        <a title="Bearbeiten" @click.prevent="edit(props.row.id)">
          <b-icon icon="square-edit-outline"></b-icon>
        </a>
      </b-table-column>

      <b-table-column v-slot="props" 
        label="L"
        class="editlink"
        field="link2"
        header-class="iconlink"
      >
        <a
          title="Löschen"
          @click.prevent="
            delete1(
              props.row.id,
              props.row.wine_name_de +
                ' ' +
                props.row.jahr +
                ': ' +
                props.row.flaschen_pro_einheit +
                ' x ' +
                props.row.menge
            )
          "
        >
          <b-icon icon="delete_forever"></b-icon>
        </a>
      </b-table-column>
  </b-table>
</template>

<script>
import View from "./articlesview.vue"
import Form from "./articlesform.vue"
export default {
  name: "articlesList",
  data() {
    return {
      nf: Intl.NumberFormat(),
      apiControllerName: "articles",
      loading: {},
      data: {
        count: 0,
        perpage: 50,
        data: [],
        page: 1,
        order: { field: "wine_name_de", direction: "ASC" },
        filter:{}
      },
      error: "",
      subtitle: "",
      isLoading: true,
      isReady: false,
      searchphrase: "",
      modal: false
    }
  },

  mounted: function() {
    this.$store.commit("setHeadline", {
      subheadline: "Artikel"
    })

    this.list()
    this.isReady = true
  },
  methods: {
    list: async function() {
      var t = this
      t.isLoading = true
      console.log(t.data.filter);
      t.$http
        .post("/" + t.apiControllerName, {
          page: t.data.page,
          search: t.searchphrase,
          order: t.data.order,
          filter: t.data.filter
        })
        .then(function(resp) {
          t.data = resp.data.data
          t.isLoading = false
          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
        })
    },
    onPageChange(page) {
      this.data.page = page
      this.list()
    },
    /*
     * Handle sort event
     */
    onSort(field, direction) {
      //console.log(field + ' » ' + direction)

      this.data.order = { field, direction }
      this.list()
    },
    view: function(id) {
      var t = this

      t.$http({
        url: "/" + t.apiControllerName + "/view/" + id+'?includestock=1',
        method: "get"
      })
        .then(function(resp) {
          //console.log( resp.data.data);

          t.modal = t.$buefy.modal.open({
            parent: t,
            component: View,
            hasModalCard: false,
            width: 1250,
            props: { data: resp.data.data }
          })

          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
        })
    },
    edit: function(id) {
      var t = this

      //this.$router.replace('/users/edit/' + id)

      t.$http({
        url: "/" + t.apiControllerName + "/view/" + id,
        method: "get"
      })
        .then(function(resp) {
          //console.log( resp.data.data);

          t.modal = t.$buefy.modal.open({
            parent: t,
            component: Form,
            hasModalCard: false,
            width: 1250,
            props: { pdata: resp.data.data }
          })

          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
        })
    },

    add: function() {
      var t = this
      var pdata = {
        wine_id: 0,
        flaschen_pro_einheit: 1,
        menge: '0.750',
        lwin: '',
        preis_net: 0,
        preis_from_private: 0,
        specialoffer: 0
      }
      t.modal = t.$buefy.modal.open({
        parent: t,
        component: Form,
        hasModalCard: false,
        scroll: "keep",
        props: { pdata: pdata }
      })
    },

    delete1: function(id, name) {
      var t = this
      t.modal = this.$buefy.dialog.confirm({
        title: "Bitte bestätigen!",
        message:
          "Wollen Sie Datensatz #" + id + ' "' + name + '" wirklich löschen?',
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.delete2(id)
      })
    },
    delete2: function(id) {
      //console.log('delete '+id);
      var t = this
      this.$http({
        url: "/" + t.apiControllerName + "/delete/" + id,
        method: "get"
      })
        .then(function(resp) {
          t.list(resp.data.data)
          t.modal.close()
          //console.log(resp.data)
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            this.$buefy.toast.open({
              message: error.response.data.message,
              type: "is-danger",
              duration: 5000
            })
          } else {
            this.$buefy.toast.open({
              message:
                "Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.",
              type: "is-danger",
              duration: 5000
            })
          }
        })
    },
    async save(formdata) {
      this.isLoading = true
      var t = this
      t.$http
        .post("/" + t.apiControllerName + "/save", { data: formdata })
        .then(function(resp) {
          t.isLoading = false

          t.list(resp.data.data)
          t.modal.close()
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
      return false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#searchbutton {
  pointer-events: all !important;
  cursor: pointer !important;
}
</style>
